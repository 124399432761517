import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, CardFooter } from './components/ui/card';
import { Label } from './components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './components/ui/select';
import { Button } from './components/ui/button';
import { Tabs, TabsList, TabsTrigger, TabsContent } from './components/ui/tabs';

const AzurePricingCalculator = () => {
  const [resourceType, setResourceType] = useState('compute');
  const [vmSeries, setVMSeries] = useState('');
  const [sku, setSku] = useState('');
  const [diskSize, setDiskSize] = useState('');
  const [region, setRegion] = useState('');
  const [priceType, setPriceType] = useState('');
  const [pricingData, setPricingData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [monthlyPrice, setMonthlyPrice] = useState(null);
  const [yearlyPrice, setYearlyPrice] = useState(null);
  const [calculatedPrices, setCalculatedPrices] = useState([]);

  const vmSeriesOptions = ['B', 'D', 'E', 'F'];
  const skuOptions = {
    'B': ['Standard_B1ms', 'Standard_B2s', 'Standard_B4ms', 'Standard_B20ms'],
    'D': ['Standard_D2s_v3', 'Standard_D4_v4', 'Standard_D8as_v5', 'Standard_D16_v3', 'Standard_D32as_v4'],
    'E': ['Standard_E2s_v3', 'Standard_E4_v4', 'Standard_E8-2s_v4', 'Standard_E16_v3', 'Standard_E32s_v4'],
    'F': ['Standard_F2s_v2', 'Standard_F4_v2', 'Standard_F8s_v2', 'Standard_F16_v2', 'Standard_F32s_v2', 'Standard_F64_v2']
  };

  useEffect(() => {
    setSku('');
  }, [vmSeries]);

  useEffect(() => {
    // Reset form when resource type changes
    setVMSeries('');
    setSku('');
    setDiskSize('');
    setRegion('');
    setPriceType('');
    setPricingData(null);
    setError(null);
    setMonthlyPrice(null);
    setYearlyPrice(null);
  }, [resourceType]);

  const getPricingData = async () => {
    let url = `http://localhost:8080/api/pricing?region=${region}&sku=${sku}&resourceType=${resourceType}`;
    if (resourceType === 'compute') {
      url += `&priceType=${priceType}`;
    }
    if (resourceType === 'manageddisk') {
      url += `&diskSize=${diskSize}`;
    }
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  };


    const calculatePrices = (pricingData) => {
        if (pricingData && pricingData.length > 0) {
          const prices = pricingData.map(item => {
            const { unitPrice, unitOfMeasure, skuName } = item;
            let monthly, yearly;
    
            if (unitOfMeasure === '1 Hour') {
              monthly = unitPrice * 24 * 30;
              yearly = monthly * 12;
            } else if (unitOfMeasure === '1/Month') {
              monthly = unitPrice;
              yearly = monthly * 12;
            } else {
              // For any other unit of measure, we'll just show the unit price
              monthly = yearly = unitPrice;
            }
    
            return {
              skuName,
              unitOfMeasure,
              unitPrice,
              monthly: monthly.toFixed(2),
              yearly: yearly.toFixed(2)
            };
          });
    
          setCalculatedPrices(prices);
        }
      };

      const handleCalculate = async () => {
        if (!sku || !region || (resourceType === 'compute' && !priceType)) {
          setError("Please fill in all required fields");
          return;
        }
        setLoading(true);
        setError(null);
        setPricingData(null);
        setCalculatedPrices([]);
        try {
          const data = await getPricingData();
          if (data.Items && data.Items.length > 0) {
            setPricingData(data.Items);
            calculatePrices(data.Items);
          } else {
            setError("No pricing data found for the given parameters.");
          }
        } catch (err) {
          setError("Error fetching pricing data: " + err.message);
        } finally {
          setLoading(false);
        }
      };

  const renderPricingData = (item) => {
    return (
      <Card key={item.meterId} className="mb-4">
        <CardHeader>
          <h3 className="text-lg font-semibold">{item.skuName} - {item.type}</h3>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 gap-2">
            {Object.entries(item).map(([key, value]) => (
              <div key={key}>
                <span className="font-medium">{key}:</span> {value}
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    );
  };

  const renderComputeForm = () => (
    <>
      <div className="space-y-2">
        <Label htmlFor="vmSeries">VM Series</Label>
        <Select value={vmSeries} onValueChange={setVMSeries}>
          <SelectTrigger id="vmSeries" className="bg-white">
            <SelectValue placeholder="Select VM Series" />
          </SelectTrigger>
          <SelectContent className="bg-white">
            {vmSeriesOptions.map(series => (
              <SelectItem key={series} value={series}>Series {series}</SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="space-y-2">
        <Label htmlFor="sku">SKU</Label>
        <Select value={sku} onValueChange={setSku} disabled={!vmSeries}>
          <SelectTrigger id="sku" className="bg-white">
            <SelectValue placeholder="Select SKU" />
          </SelectTrigger>
          <SelectContent className="bg-white">
            {vmSeries && skuOptions[vmSeries].map(skuName => (
              <SelectItem key={skuName} value={skuName}>{skuName}</SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </>
  );

  const renderPublicIPForm = () => (
    <div className="space-y-2">
      <Label htmlFor="sku">SKU</Label>
      <Select value={sku} onValueChange={setSku}>
        <SelectTrigger id="sku" className="bg-white">
          <SelectValue placeholder="Select SKU" />
        </SelectTrigger>
        <SelectContent className="bg-white">
          <SelectItem value="Basic">Basic</SelectItem>
          <SelectItem value="Standard">Standard</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );

  const renderStorageAccountForm = () => (
    <div className="space-y-2">
      <Label htmlFor="sku">Account Type</Label>
      <Select value={sku} onValueChange={setSku}>
        <SelectTrigger id="sku" className="bg-white">
          <SelectValue placeholder="Select Account Type" />
        </SelectTrigger>
        <SelectContent className="bg-white">
          <SelectItem value="Standard_LRS">Standard LRS</SelectItem>
          <SelectItem value="Standard_GRS">Standard GRS</SelectItem>
          <SelectItem value="Standard_RAGRS">Standard RA-GRS</SelectItem>
          <SelectItem value="Premium_LRS">Premium LRS</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );

  const renderManagedDiskForm = () => (
    <>
      <div className="space-y-2">
        <Label htmlFor="sku">Disk Type</Label>
        <Select value={sku} onValueChange={setSku}>
          <SelectTrigger id="sku" className="bg-white">
            <SelectValue placeholder="Select Disk Type" />
          </SelectTrigger>
          <SelectContent className="bg-white">
            <SelectItem value="LRS">Standard LRS</SelectItem>
            <SelectItem value="ZRS">Standard ZRS</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div className="space-y-2">
        <Label htmlFor="diskSize">Size</Label>
        <Select value={diskSize} onValueChange={setDiskSize}>
          <SelectTrigger id="diskSize" className="bg-white">
            <SelectValue placeholder="Select Disk Size" />
          </SelectTrigger>
          <SelectContent className="bg-white">
            <SelectItem value="P10">P10 - 128GB</SelectItem>
            <SelectItem value="P20">P20 - 512GB</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </>
  );

  return (
    <div className="container mx-auto p-4">
      <Tabs value={resourceType} onValueChange={setResourceType} className="w-full mb-6">
        <TabsList className="grid w-full grid-cols-4">
          <TabsTrigger value="compute">Compute</TabsTrigger>
          <TabsTrigger value="publicip">Public IP</TabsTrigger>
          <TabsTrigger value="storage">Storage Accounts</TabsTrigger>
          <TabsTrigger value="manageddisk">Managed Disks</TabsTrigger>
        </TabsList>
      </Tabs>

      <Card className="mb-8">
        <CardHeader>
          <h2 className="text-2xl font-bold text-center">Azure {resourceType.charAt(0).toUpperCase() + resourceType.slice(1)} - Pricing Calculator</h2>
        </CardHeader>
        <CardContent>
          <form className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {resourceType === 'compute' && renderComputeForm()}
              {resourceType === 'publicip' && renderPublicIPForm()}
              {resourceType === 'storage' && renderStorageAccountForm()}
              {resourceType === 'manageddisk' && renderManagedDiskForm()}
              <div className="space-y-2">
                <Label htmlFor="region">Region</Label>
                <Select value={region} onValueChange={setRegion}>
                  <SelectTrigger id="region" className="bg-white">
                    <SelectValue placeholder="Select a region" />
                  </SelectTrigger>
                  <SelectContent className="bg-white">
                    <SelectItem value="eastus">East US</SelectItem>
                    <SelectItem value="westus">West US</SelectItem>
                    <SelectItem value="westeurope">West Europe</SelectItem>
                    <SelectItem value="southindia">South India</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              {resourceType === 'compute' && (
                <div className="space-y-2">
                  <Label htmlFor="priceType">Price Type</Label>
                  <Select value={priceType} onValueChange={setPriceType}>
                    <SelectTrigger id="priceType" className="bg-white">
                      <SelectValue placeholder="Select a price type" />
                    </SelectTrigger>
                    <SelectContent className="bg-white">
                      <SelectItem value="Consumption">Consumption</SelectItem>
                      <SelectItem value="Reservation">Reservation</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              )}
            </div>
          </form>
        </CardContent>
        <CardFooter>
          <Button onClick={handleCalculate} disabled={loading} className="w-full transition-colors duration-200">
            {loading ? 'Fetching Data...' : 'Get Pricing Data'}
          </Button>
        </CardFooter>
      </Card>
      {error && (
        <Card className="mb-4 bg-red-50">
          <CardContent>
            <p className="text-red-600">{error}</p>
          </CardContent>
        </Card>
      )}
      
      {pricingData && (
        <>
          <div>
            <h3 className="text-xl font-semibold mb-4">Pricing Data:</h3>
            {pricingData.map(renderPricingData)}
          </div>
          
          <Card className="mt-8">
            <CardHeader>
              <h3 className="text-xl font-semibold">Estimated Costs</h3>
            </CardHeader>
            <CardContent>
              {calculatedPrices.map((price, index) => (
                <div key={index} className="mb-6 pb-6 border-b border-gray-200 last:border-b-0">
                  <h4 className="text-lg font-semibold mb-2">{price.skuName}</h4>
                  <div className="grid grid-cols-3 gap-4">
                    <div>
                      <p className="font-medium">Unit Price:</p>
                      <p className="text-xl font-bold">${price.unitPrice} per {price.unitOfMeasure}</p>
                    </div>
                    <div>
                      <p className="font-medium">Monthly Estimate:</p>
                      <p className="text-xl font-bold">${price.monthly}</p>
                    </div>
                    <div>
                      <p className="font-medium">Yearly Estimate:</p>
                      <p className="text-xl font-bold">${price.yearly}</p>
                    </div>
                  </div>
                </div>
              ))}
              <p className="mt-4 text-sm text-gray-500">
                Note: These estimates are based on continuous usage and may vary depending on actual consumption patterns. 
                For hourly rates, calculations assume 24/7 usage. Monthly rates are directly from the pricing data.
              </p>
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
};

export default AzurePricingCalculator;