import React from 'react';
import AzurePricingCalculator from './AzurePricingCalculator';

function App() {
  return (
    <div className="App">
      <AzurePricingCalculator />
     {/* test comment */}
    </div>
  );
}

export default App;